<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    viewBox="0 0 20 20"
    width="20"
    height="20"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M10 8.75a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
    />
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M17.5 8.75c0 5.834-7.5 10-7.5 10s-7.5-4.166-7.5-10a7.5 7.5 0 1 1 15 0Z"
    />
  </svg>
</template>
